<template>
  <div>
    <CCard v-if="mode == 'default'">
      <CCardHeader class="d-flex flex-wrap align-items-center">
        <TMessage content="New product" bold />
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            class="mr-2"
            @click="reset"
            :options="{ iconSize: 16 }"
          />
          <TButton
            icon="cil-plus"
            content="Create product"
            class="font-weight-bold"
            @click="create"
            :options="{ disabled: disabled }"
          />
        </div>
      </CCardHeader>
      <CCardBody :key="formKey">
        <CRow class="mb-2">
          <CCol col="12" lg="6">
            <CRow>
              <CCol col="12" lg="6">
                <TInputText
                  placeholder="Product Id"
                  label="Product Id"
                  class="mb-3"
                  @update:value="product.id = $event"
                />
              </CCol>
              <CCol col="12" lg="6">
                <TInputText
                  placeholder="Product code"
                  label="Product code"
                  class="mb-3"
                  @update:value="product.code = $event"
                />
              </CCol>
            </CRow>
            <TInputText
              placeholder="Customs clearance code"
              label="Customs clearance code"
              class="mb-3"
              @update:value="product.hs_code = $event"
            />
            <CRow>
              <CCol col="12" lg="6">
                <TInputMoney
                  label="Regular price"
                  class="mb-3"
                  @update:value="product.initial_price = $event"
                />
              </CCol>
              <CCol col="12" lg="6">
                <TInputNumber
                  label="Percentage off"
                  class="mb-3"
                  @update:value="product.discount = $event"
                  :maskOptions="{ integerLimit: 2 }"
                />
              </CCol>
            </CRow>
            <TInputText
              placeholder="Product name"
              label="Product name"
              class="mb-3"
              @update:value="product.name = $event"
            />
            <TInputText
              placeholder="Brand"
              label="Brand"
              class="mb-3"
              @update:value="product.brand = $event"
            />
            <TInputText
              placeholder="Link store"
              label="Link store"
              class="mb-3"
              @update:value="product.link_store = $event"
            />
            <TInputText
              placeholder="Manufacturing year"
              label="Manufacturing year"
              class="mb-3"
              @update:value="product.manufacturing_year = $event"
              :inputOptions="{ description: 'Ex: 2020' }"
            />
            <SSelectProductEc
              label="Ecommerce channel"
              @update:value="product.ecommerce_channel_id = $event"
              class="mb-3"
            />
            <SSelectProductCategory
              label="Category"
              :prepend="[{ id: '', name: $t('Select') }]"
              @update:value="product.category_id = $event"
              class="mb-3"
            />
            <SSelectProductOrigin
              label="Origin"
              @update:value="product.origin_id = $event"
              class="mb-3"
            />
            <TInputTextarea
              placeholder="Ingredients"
              @update:value="product.ingredients = $event"
              label="Ingredients"
              class="mb-3"
            />
          </CCol>
          <CCol col="12" lg="6" class="mb-3">
            <TMessage content="Embargo & more" class="mb-2" bold />
            <TInputCheckbox
              :checked.sync="product.special"
              @change="product.special = $event"
              label="Special goods"
              class="mb-1"
            />
            <TInputCheckbox
              :checked="product.private_license"
              @change="product.private_license = $event"
              label="Private license"
              class="mb-1"
            />
            <TInputCheckbox
              :checked="product.banned_export"
              @change="product.banned_export = $event"
              label="Banned export"
              class="mb-1"
            />
            <TInputCheckbox
              :checked="product.banned_air"
              @change="product.banned_air = $event"
              label="Banned air"
              class="mb-1"
            />
            <TInputCheckbox
              :checked="product.banned_sea"
              @change="product.banned_sea = $event"
              label="Banned sea"
              class="mb-1"
            />
            <hr />
            <TMessage
              content="Product box specifications"
              class="mb-2 mt-4"
              bold
            />
            <CRow>
              <CCol col="12" lg="6">
                <TInputNumber
                  label="Weight"
                  class="mb-3"
                  @update:value="product.weight = $event"
                />
              </CCol>
              <CCol col="12" lg="6">
                <TInputNumber
                  label="Height"
                  class="mb-3"
                  @update:value="product.height = $event"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" lg="6">
                <TInputNumber
                  label="Width"
                  class="mb-3"
                  @update:value="product.width = $event"
                />
              </CCol>
              <CCol col="12" lg="6">
                <TInputNumber
                  label="Length"
                  class="mb-3"
                  @update:value="product.length = $event"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol col="12" lg="6" class="mb-3">
            <TMessage content="Featured image" class="mb-2" bold />
            <CImg
              style="height: 300px; object-fit: contain"
              thumbnail
              fluidGrow
              :src="image ? image : image_url"
              placeholderColor
              class="mb-3 p-0"
            />
            <TInputFile
              accept="image/*"
              :value.sync="file"
              @update:value="onFileChange($event)"
              :multiple="false"
              class="w-100"
            />
            <TInputText
              :value.sync="image_url"
              @update:value="imgUrlChange"
              class="w-100 mt-2"
              placeholder="Enter image url"
              :inputOptions="{ prepend: 'URL' }"
            />
          </CCol>
          <CCol col="12" lg="6" class="mb-3">
            <TMessage content="Product albums" class="mb-2" bold />
            <TInputFile
              accept="image/*"
              :value.sync="album"
              :multiple="true"
              theme=""
              :maxFiles="5"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <TModal
      v-if="mode == 'quicknew'"
      title="Create product"
      :show="show"
      @update:show="$emit('update:show', $event)"
    >
      <template #actions>
        <TButton
          icon="cil-plus"
          content="Create and add"
          class="font-weight-bold"
          @click="create"
          :options="{ disabled: disabled }"
        />
      </template>
      <CRow class="mb-2">
        <CCol col="12" lg="12">
          <CRow>
            <CCol col="12" lg="6">
              <TInputText
                placeholder="Jancode"
                label="Jancode"
                class="mb-3"
                :value.sync="product.id"
                @update:value="product.id = $event"
              />
            </CCol>
            <CCol col="12" lg="6">
              <TInputText
                placeholder="Product Id"
                label="Product Id"
                class="mb-3"
                :value.sync="product.code"
                @update:value="product.code = $event"
              />
            </CCol>
            <CCol>
              <TInputText
                placeholder="Product name"
                label="Product name"
                class="mb-3"
                :value.sync="product.name"
                @update:value="product.name = $event"
              />
              <TInputText
                placeholder="Brand"
                label="Brand"
                class="mb-3"
                @update:value="product.brand = $event"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol col="12" lg="12" class="mb-3">
          <TMessage content="Featured image" class="mb-2" bold />
          <CImg
            style="height: 250px; object-fit: contain"
            thumbnail
            fluidGrow
            :src="image ? image : image_url"
            placeholderColor
            class="mb-3 p-0"
          />
          <TInputFile
            accept="image/*"
            :value.sync="file"
            @update:value="onFileChange($event)"
            :multiple="true"
            class="w-100"
          />
          <TInputText
            :value.sync="image_url"
            @update:value="imgUrlChange"
            class="w-100 mt-2"
            placeholder="Enter image url"
            :inputOptions="{ prepend: 'URL' }"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
      default: "default",
    },
    show: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      product: {
        special: false,
        private_license: false,
        banned_export: false,
        banned_air: false,
        banned_sea: false,
        weight: 0,
        height: 0,
        length: 0,
        width: 0,
        initial_price: 0,
        discount: 0,
      },
      image: "",
      disabled: false,
      file: [],
      album: [],
      image_url: "",
      formKey: "formKey",
    };
  },
  watch: {
    product_id: {
      immediate: true,
      handler(id) {
        this.product.id = id;
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.formKey = "" + this.lodash.random(10000, 100000);
    },
    create() {
      this.disabled = true;
      let data = {
        ...this.product,
        "images[0][file]": this.file.length ? this.file[0].file : undefined,
        "images[0][url]": this.image_url ? this.image_url : undefined,
        special: +this.product.special,
        private_license: +this.product.private_license,
        banned_export: +this.product.banned_export,
        banned_air: +this.product.banned_air,
        banned_sea: +this.product.banned_sea,
        "images[0][primary]": 1,
      };
      this.album.forEach((x, i) => {
        const item = { [`images[${i + 1}][file]`]: x.file };

        data = { ...data, ...item };
      });
      const dataClean = this.lodash.omitBy(data, this.lodash.isNil);
      this.$store
        .dispatch("product.products.create", dataClean)
        .then((data) => {
          if (this.mode == "default") this.$router.push({ path: `${data.id}` });
          else {
            this.reset();
            this.$emit("update:show", false);
            this.$emit("created", data);
          }
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    imgUrlChange(e) {
      this.image = "";
      this.image_url = e;
      this.file = [];
    },
    onFileChange(file) {
      if (this.file.length == 1) {
        this.image = URL.createObjectURL(file[0].file);
      } else {
        this.image = "";
      }
      this.image_url = "";
    },
  },
};
</script>
